import { useToast as toastLibraryProxy } from "vue-toastification"

/**
 * A wrapper around the toast library of choice
 * we are simply wrapping useToast so if we ever need to change
 * libraries we can do so in one place
 */

export const useToast = () => {
	return toastLibraryProxy()
}

/**
 * plugins/index.js
 *
 * Automatically included in `./src/main.js`
 */

// Plugins
import { loadFonts } from "./webfontloader"
import pinia from "../Store"
import registerLaravelVueI18nPlugin from "./laravel_vue_i18n"
import registerToastification from "./toastification"
import loadZiggy from "./ziggy"
import loadSentry from "./sentry"
import registerMitt from "./mitt"
import AssetUploader from "@/Components/AssetUploader.vue"
import { install as VueMonacoEditorPlugin } from "@guolao/vue-monaco-editor"

export function registerPlugins(app, usePinia = true) {
	loadFonts()
	loadSentry(app)
	registerLaravelVueI18nPlugin(app)
	registerToastification(app)
	loadZiggy(app)
	registerMitt(app)
	app.use(pinia)
	app.use(VueMonacoEditorPlugin)
	// if (usePinia){
	// }
	app.component("AssetUploader", AssetUploader)
}
export function registerPortalPlugins(app, usePinia = true) {
	loadFonts()
	loadZiggy(app)
	app.use(pinia)
}

import { i18nVue } from "laravel-vue-i18n"

export default function laravelVueI18nPlugin(app) {
	app.use(i18nVue, {
		resolve: async (lang) => {
			const langs = import.meta.glob("../../../lang/php_*.json")
			const data = await langs[`../../../lang/php_${lang}.json`]()
			return data
		},
	})
}

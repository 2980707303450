import { usePage } from "@inertiajs/vue3"

export function useTeamRouteParams() {
	const injectTeamParam = (routeName, params = {}) => {
		// Only inject team if route contains 'dashboard.project' and team param is not already set
		const routeNames = ["dashboard.project", "dashboard.plugin"]
		if (routeNames.some((route) => routeName.includes(route)) && !params.team) {
			const currentTeam = usePage().props.currentTeam
			if (currentTeam) {
				return {
					...params,
					team: currentTeam.slug,
				}
			}
		}

		// if params.team == {{current_team}} then replcae it with the slug
		if (params.team === "{{current_team}}") {
			const currentTeam = usePage().props.currentTeam
			if (currentTeam) {
				return {
					...params,
					team: currentTeam.slug,
				}
			}
		}

		return params
	}

	return {
		injectTeamParam,
	}
}

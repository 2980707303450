// import { fas, far, fal, fat, fab } from "@awesome.me/kit-b1b42196dd/icons"
import * as fas from "@awesome.me/kit-b1b42196dd/icons/classic/solid"
import * as far from "@awesome.me/kit-b1b42196dd/icons/classic/regular"
import * as fal from "@awesome.me/kit-b1b42196dd/icons/classic/light"
import * as fat from "@awesome.me/kit-b1b42196dd/icons/classic/thin"
import * as fab from "@awesome.me/kit-b1b42196dd/icons/classic/brands"

export const lookupIcon = (iClass, iStyle = "far") => {
	if (!iClass) return {}
	let iconClass =
		"fa" + toTitleCase(iClass.replace(/-/g, " ")).replace(/ /g, "")
	let iconStyle = iStyle
	let icon = {}
	switch (iconStyle) {
		case "far":
			icon = far[iconClass] || {}
			break
		case "fal":
			icon = fal[iconClass] || {}
			break
		case "fat":
			icon = fat[iconClass] || {}
			break
		case "fab":
			icon = fab[iconClass] || {}
			break
		default:
			icon = fas[iconClass] || {}
			break
	}
	return icon
}
function toTitleCase(str) {
	return str.replace(
		/\w\S*/g,
		(text) => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase(),
	)
}

<template>
	<!-- Modal Button (Assuming you want to open the modal using a button) -->
	<button
		type="button"
		role="button"
		class="btn btn-primary btn-sm shadow-0 create-dialog-btn btn-rounded"
		@click.stop="(dialog = !dialog), (files = [])"
	>
		<span class="create-btn-text">
			<FontAwesomeIcon :icon="lookupIcon('cloud-arrow-up', 'fas')" />
			Upload
		</span>
	</button>

	<!-- MDB Modal -->
	<MDBModal v-model="dialog" size="lg">
		<MDBModalBody>
			<button
				class="float-end btn border border-1 text-black shadow-0 btn-floating btn-sm"
				type="button"
				@click.prevent="dialog = false"
			>
				<IconBadge icon="fat.xmark" size="lg" :options="{ classList: [] }" />
			</button>
			<h4 class="text-center mt-3 fw-bold">Upload an Asset</h4>
			<h6 class="text-center fw-light mb-4"></h6>
			<div
				v-if="loading"
				class="progress"
				:style="{ opacity: loading ? 1 : 0 }"
			>
				<div
					class="progress-bar progress-bar-striped progress-bar-animated mx-auto"
					role="progressbar"
					aria-valuenow="75"
					aria-valuemin="0"
					aria-valuemax="100"
					style="width: 100%"
				></div>
			</div>
			<div
				v-if="!loading"
				class="d-flex flex-column gap-4"
				style="max-width: 650px; margin: auto"
			>
				<div
					id="dropzone-container"
					:class="{ dragging: isDragging }"
					@dragover="dragover"
					@dragleave="dragleave"
					@drop="drop"
				>
					<input
						id="fileInput"
						ref="fileGroup"
						type="file"
						multiple
						name="file"
						class="hidden-input"
						accept=".pdf,.jpg,.jpeg,.png"
						@change="onChange"
					/>
					<label
						for="fileInput"
						class="file-label text-center position-relative"
						@dragleave.stop="() => false"
					>
						<div class="lh-1 d-flex flex-column gap-2 py-3">
							<img
								src="/images/asset_upload.png"
								alt="Upload Image"
								class="mx-auto"
								style="max-width: 60px"
							/>
							<h5 class="fw-bold mb-0" style="font-size: 1.125rem">
								Drag File Here
							</h5>
							<div style="font-size: 0.75rem">Or</div>
							<div
								style="font-size: 0.875rem"
								class="btn-outline-primary rounded-pill align-self-center"
							>
								<IconBadge icon="far.cloud-arrow-up" />
								<span class="fw-bold">Upload from Computer</span>
							</div>
						</div>
						<div
							v-if="isDragging"
							class="position-absolute top-0 d-flex h-100 w-100 justify-content-center align-items-center bg-white"
						>
							Release to drop files here.
						</div>
					</label>
					<div v-if="files.length" class="preview-container mt-4 row">
						<MDBAccordion>
							<MDBAccordionItem>
								<div
									v-for="file in files"
									:key="file.file.name"
									:title="file.file.name"
									class="row align-items-center"
								>
									<div class="col-1">
										<MDBBtn
											class="btn-danger btn-sm ml-2"
											type="button"
											title="Remove file"
											@click="remove(files.indexOf(file))"
										>
											&times;
										</MDBBtn>
									</div>
									<div class="col-3">
										<img
											class="preview-img"
											:src="generateThumbnail(file.file)"
										/>
									</div>
									<div class="col-8">
										<TextField v-model="file.name" label="Rename File" />
										<div><b>Size:</b> {{ file.file.size / 1000 }} KB</div>
									</div>
								</div>
							</MDBAccordionItem>
						</MDBAccordion>
					</div>
				</div>
				<MDBBtn
					:disabled="files.length === 0"
					color="primary"
					class="btn btn-primary m-auto ripple-surface"
					@click="onSubmit"
				>
					Upload
				</MDBBtn>
			</div>
		</MDBModalBody>
	</MDBModal>
</template>

<script setup>
import { ref } from "vue"
import { useAssetStore } from "@/Store/assetStore"
import { useProjectStore } from "@/Store/projectStore"
import { router, usePage } from "@inertiajs/vue3"
import { useToast } from "@/Composables/useToast"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { lookupIcon } from "@/Composables/useAwesomeIcons"
import IconBadge from "@/Components/IconBadge.vue"
import TextField from "@/Components/Mod/FormFields/TextField.vue"
import {
	MDBAccordion,
	MDBAccordionItem,
	MDBBtn,
	MDBCol,
	MDBContainer,
	MDBInput,
	MDBModal,
	MDBModalBody,
	MDBModalFooter,
	MDBModalHeader,
	MDBModalTitle,
	MDBRow,
} from "mdb-vue-ui-kit"
import axios from "axios"
import route from "ziggy-js"
import { storeToRefs } from "pinia"
import { useTeamRouteParams } from "@/Composables/useTeamRouteParams"

const { injectTeamParam } = useTeamRouteParams()
const emit = defineEmits(["success"])
const props = defineProps({
	formDefinition: {
		type: Object,
		default: () => {
			return {}
		},
	},
	size: {
		type: String,
		default: "xl",
	},
	showOnMounted: {
		type: Boolean,
		default: false,
	},
})

const dialog = ref(false)
const isDragging = ref(false)
const fileGroup = ref(null)
const files = ref([])
const loading = ref(false)

const onSubmit = () => {
	const formData = new FormData()
	let uploadRoute = ""
	if (props.formDefinition?.create) {
		let newParams = props.formDefinition.create.params

		newParams = injectTeamParam(props.formDefinition.create.route, newParams)
		uploadRoute = route(props.formDefinition.create.route, newParams)
		let assetFileName = "asset[]"
		if (props.formDefinition.assetFileName) {
			assetFileName = props.formDefinition.assetFileName + "[]"
		}
		files.value.forEach((file) => {
			formData.append(assetFileName, file.file)
			formData.append("asset_name[]", file.name)
		})
	} else {
		const projectStore = useProjectStore()
		const project_id = projectStore.getActiveProject.slug
		const assetStore = useAssetStore()

		files.value.forEach((file) => {
			formData.append("asset[]", file.file)
		})
		uploadRoute = route("dashboard.project.assets.store", {
			project: project_id,
			team: usePage().props.currentTeam.slug,
		})
	}
	const toast = useToast()

	router.post(uploadRoute, formData, {
		onSuccess: (event) => {
			toast.success("Successfully uploaded assets")
			emit("success")
		},
		onError: (errors) => {
			toast.error(Object.values(errors).join("\n"))
		},
	})

	files.value = []
	dialog.value = false
}
const onChange = () => {
	Array.from(fileGroup.value.files).forEach((file) => {
		files.value.push({ file: file, name: file.name })
	})
}
const generateThumbnail = (file) => {
	const fileSrc = URL.createObjectURL(file)
	setTimeout(() => {
		URL.revokeObjectURL(fileSrc)
	}, 1000)
	return fileSrc
}
const remove = (i) => {
	files.value.splice(i, 1)
}
const dragover = (e) => {
	e.preventDefault()
	isDragging.value = true
}
const dragleave = (e) => {
	if (e.target.id !== "dropzone-container") return
	isDragging.value = false
}
const drop = (e) => {
	e.preventDefault()
	fileGroup.value.files = e.dataTransfer.files
	onChange()
	isDragging.value = false
}
</script>

<style lang="css" scoped>
.main {
	display: flex;
	flex-grow: 1;
	align-items: center;
	height: 100vh;
	justify-content: center;
	text-align: center;
}
#dropzone-container {
	border: 1px dashed #222222;
	max-width: 650px;
	margin: auto;
	width: 100%;
}
#dropzone-container.dragging * {
	pointer-events: none !important;
}
.hidden-input {
	opacity: 0;
	overflow: hidden;
	position: absolute;
	width: 1px;
	height: 1px;
}
.file-label {
	font-size: 20px;
	display: block;
	cursor: pointer;
}
.preview-container {
	display: flex;
	margin-top: 2rem;
}
.preview-card {
	display: flex;
	border: 1px solid #a2a2a2;
	padding: 5px;
	margin-left: 5px;
}
.preview-img {
	width: 100%;
	height: auto;
	border-radius: 5px;
	border: 1px solid #a2a2a2;
	background-color: #a2a2a2;
}
@media (max-width: 1000px) {
	:deep(.modal-dialog.modal-xl) {
		--mdb-modal-width: 80%;
	}
}
</style>

<style>
#dropzone-container .accordion-body {
	display: flex;
	flex-direction: column;
	gap: 16px;
	min-height: 70px;
}
#dropzone-container .accordion-header + .show {
	overflow-y: auto;
	min-height: 200px;
}
</style>

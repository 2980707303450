// Utilities
import { defineStore } from "pinia"
const PROJECT_LIST_LOCAL_STORAGE_KEY = "project-list"

export const useProjectStore = defineStore("projects", {
	state: () => ({
		projectList: [],
		breadCrumbs: [],
		recentProjects:
			JSON.parse(localStorage.getItem(PROJECT_LIST_LOCAL_STORAGE_KEY)) || {},
		projectsPaginated: {},
		activeProject: null,
	}),
	getters: {
		getPaginatedProjectList() {
			if (!this.projectsPaginated || !this.projectsPaginated.data) return null
			return this.projectsPaginated.data
		},
		getActiveProjectId() {
			if (!this.activeProject) return null
			return this.activeProject.id
		},
		getActiveProject() {
			return this.activeProject
		},
	},
	actions: {
		setProjectList(value) {
			this.projectList = value
		},
		setProjectsPaginated(value) {
			this.projectsPaginated = value
		},
		setActiveProject(adminId, value) {
			this.activeProject = value
			let teamId = value?.team_id ?? null
			if (value && teamId) {
				try {
					if (
						this.recentProjects[adminId] == undefined ||
						!Array.isArray(this.recentProjects[adminId])
					) {
						this.recentProjects[adminId] = []
					}
					if (
						this.recentProjects[adminId][teamId] == undefined ||
						!Array.isArray(this.recentProjects[adminId][teamId])
					) {
						this.recentProjects[adminId][teamId] = []
					}
					this.recentProjects[adminId][teamId] = this.recentProjects[adminId][
						teamId
					].filter((project) => project.id !== value.id)
					if (this.recentProjects[adminId][teamId].length > 4) {
						this.recentProjects[adminId][teamId].splice(
							1,
							this.recentProjects[adminId][teamId].length - 4,
						)
					}

					this.recentProjects[adminId][teamId].unshift(value)
					localStorage.setItem(
						PROJECT_LIST_LOCAL_STORAGE_KEY,
						JSON.stringify(this.recentProjects),
					)
				} catch (err) {
					console.error(err)
					localStorage.setItem(PROJECT_LIST_LOCAL_STORAGE_KEY, {})
				}
			}
		},
		setBreadCrumbs(value) {
			this.breadCrumbs = value
		},
		getRecentProjects(adminId, teamId) {
			if (teamId === undefined) return null
			if (!this.recentProjects) return null
			// if (Array.isArray(this.recentProjects)) return null
			if (!this.recentProjects[adminId]) return null
			if (!this.recentProjects[adminId][teamId]) return null
			if (!Array.isArray(this.recentProjects[adminId][teamId])) return null
			return this.recentProjects[adminId][teamId].filter((item) => {
				return !Array.isArray(item)
			})
		},
		removeFromRecents(adminId, teamId, projectId) {
			try {
				if (this.recentProjects[adminId] == undefined) {
					this.recentProjects[adminId] = []
				} else if (this.recentProjects[adminId][teamId] == undefined) {
					this.recentProjects[adminId][teamId] = []
				} else {
					this.recentProjects[adminId][teamId] = this.recentProjects[adminId][
						teamId
					].filter((project) => {
						return project.id != projectId
					})
					localStorage.setItem(
						PROJECT_LIST_LOCAL_STORAGE_KEY,
						JSON.stringify(this.recentProjects),
					)
				}
			} catch (err) {
				console.error(err)
				localStorage.setItem(PROJECT_LIST_LOCAL_STORAGE_KEY, {})
			}
		},
	},
})

import Toast, { POSITION } from "vue-toastification"
import "vue-toastification/dist/index.css"
;("https://github.com/Maronato/vue-toastification")

const defaultOptions = {
	toastClassName: "custom-toast-message",
	bodyClassName: "custom-toast-message-body",
	containerClassName: "custom-toast-message-container",
	hideProgressBar: true,
	timeout: 3000,
}

export default function registerToastification(app) {
	app.use(Toast, defaultOptions)
}
